import React from "react"
import { useIntl } from "react-intl"
import { ArticleEntity } from "../../entities/ArticleEntity"
import {
  getBlogUrl,
  getPopularUrl,
  getPrivacyUrl,
  getTermsUrl,
} from "../../utils/i18nUrls"
import { Category } from "../Category/Category"
import { FooterLinks } from "../FooterLinks/FooterLinks"
import { FormattedMessage } from "../FormattedMessage/FormattedMessage"
import { Image } from "../Image/Image"
import { Link } from "../Link/Link"
import { H1 } from "../Mdx/components"
import { MobileNavigation } from "../MobileNavigation/MobileNavigation"
import { SearchInput } from "../SearchInput/SearchInput"
import { StaticPageContainer } from "../StaticPageContainer/StaticPageContainer"
import { StaticPageHeading } from "../StaticPageHeading/StaticPageHeading"

export const HomeSearch: React.FC<{ logo: any }> = props => {
  const intl = useIntl()
  return (
    <>
      <section className="relative min-h-screen pt-6 w-full">
        <div className="w-full px-4">
          <div className="max-w-2xl mt-8 lg:mt-32 mx-auto">
            <div className="text-center">
              <Image
                className="h-20 w-20 inline-block"
                src={props.logo.src.publicURL}
                alt={props.logo.alt}
              />
            </div>
            <div className="text-center mt-4">
              <h1 className="mx-auto max-w-3xl px-4 md:px-0 font-display text-4xl font-bold">
                Munbeob 문법
              </h1>
              <p className="text-lg">
                <FormattedMessage id="home/description" />
              </p>
            </div>
            <div className="mt-8">
              <SearchInput showSubmitButton />
            </div>

            <FooterLinks />
          </div>
        </div>
      </section>
      <MobileNavigation />
    </>
  )
}
